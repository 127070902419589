import styled from "@emotion/styled";
import { Typography } from "src/components";
import theme from "src/theme";

export const Form = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: 20,
  flex: 1,
  margin: "26px 0",
});

export const SkipButton = styled("div")({
  marginTop: "24px",
  marginBottom: "15px",
});

export const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
});

export const Hint = styled(Typography)({
  fontWeight: 400,
  color: theme.palette.grey[600],
  textAlign: "center",
  marginTop: "8px",
});
