import banner1 from "src/assets/images/banner1.svg";
import banner2 from "src/assets/images/banner2.svg";

export const content = [
  {
    text: "You submitted everything and your expert will be sharing feedback with you soon.",
    state: {
      field: "current_part_status",
      model: "module" as "module",
      value: 2,
    },
    color: "#5B2FDB",
    title: "Great work!",
    prioritized: false,
    icon: banner1,
    id: 1,
    query_param: "waiting_for_review=true",
  },
  {
    text: "Your expert has opened up a new level for you. Click here to continue",
    state: {
      field: "status",
      model: "assigned_part" as "assigned_part",
      value: 9,
    },
    color: "#37AC84",
    title: "New part assigned",
    prioritized: true,
    icon: banner2,
    id: 6,
    query_param: "complete_part=true",
  },
];
