import React from "react";
import { Carousel } from "src/components/Carousel";
import { useCarouselContext } from "../../contexts/CarouselContext";
import { CarouselWrapper, MainCarouselContainer, Title, Text } from "../styles";
import { CarouselData } from "../../types";

export const MainCarousel = ({ data }: { data: CarouselData[] }) => {
  const { contentCarouselRef } = useCarouselContext();

  return (
    <MainCarouselContainer>
      <CarouselWrapper>
        <Carousel
          data={data}
          ref={contentCarouselRef}
          renderItem={(item: any) => (
            <>
              <Title variant="headline3">{item.title}</Title>
              <Text variant="body3">{item.text}</Text>
            </>
          )}
        />
      </CarouselWrapper>
    </MainCarouselContainer>
  );
};
