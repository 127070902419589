import { Growthwork } from "src/api/types";
import { TypeformEmbed } from "src/components";
import useLocationState from "src/hooks/useLocationState";
import { getTypeformId } from "./helpers";
import { useCompleteGrowthworkMutation, useGetMemberQuery } from "src/api/main";
import { ROUTES } from "src/types/routes";
import { useNavigate } from "react-router-dom";

interface GrowthworkState extends Growthwork {
  uuid: string;
  url_hash: string;
}

const QuestionnaireTask = () => {
  const growthwork = useLocationState<GrowthworkState>("growthwork");
  const [completeGrowthwork] = useCompleteGrowthworkMutation();
  const { data: member } = useGetMemberQuery();
  const navigate = useNavigate();

  const onSubmit = () => {
    if (!member || !growthwork) return;

    completeGrowthwork({
      member: member.uuid,
      growthworkId: growthwork.uuid,
    })
      .unwrap()
      .then(() => {
        navigate(ROUTES.TASK_COMPLETED, { state: { growthwork } });
      });
  };

  return (
    <TypeformEmbed
      formId={getTypeformId(growthwork?.baselink)}
      onSubmit={onSubmit}
      hash={growthwork?.url_hash || ""}
    />
  );
};

export default QuestionnaireTask;
