import { buildUrl } from "src/api/cmsUrlBuilder";

export const urls = {
  getOnboardingSlides: () =>
    buildUrl("onboarding-carousel-slides", {
      populate: ["*"],
      sort: ["order"],
      filters: [
        {
          matchers: ["$or", "0", "platform", "$eq"],
          value: "all",
        },
        {
          matchers: ["$or", "1", "platform", "$eq"],
          value: "webapp",
        },
      ],
    }),
};
