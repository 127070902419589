import { styled } from "@mui/material";
import Typography from "src/components/Typography";
import theme from "src/theme";

export const MainCarouselContainer = styled("div")({
  alignItems: "center",
  marginTop: "16px",
  flex: 1,
});

export const CarouselWrapper = styled("div")({
  marginTop: "24px",
});

export const Title = styled(Typography)({
  textAlign: "center",

  [theme.breakpoints.down("sm")]: {
    fontSize: "22px",
    lineHeight: "30px",
  },
});

export const Text = styled(Typography)({
  textAlign: "center",
  marginTop: "24px",
  color: theme.palette.grey[600],
  fontWeight: 400,
});

export const HeaderWrapper = styled("div")({
  backgroundColor: "#FEFCF3",
  paddingTop: "16px",
});

export const ScreenLayout = styled("div")({
  paddingBottom: "16px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
});

export const CloseButton = styled("div")({
  position: "absolute",
  left: "20px",
  zIndex: 1000000,
  paddingTop: "20px",
});

export const MainContentWrapper = styled("div")({
  padding: "0 20px",
  display: "flex",
  flexDirection: "column",
  flex: 1,
});

export const Image = styled("img")({
  height: "370px",
  objectFit: "cover",
});
