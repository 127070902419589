import { Typography } from "@mui/material";
import { Button } from "../Button";
import { useAuth0 } from "@auth0/auth0-react";
import InfoLayout from "../InfoLayout";

const ErrorLayout = ({ subtitle }: { subtitle?: string }) => {
  const { logout } = useAuth0();

  return (
    <InfoLayout
      title="Oops, something went wrong"
      subtitle={
        subtitle ||
        "We couldn’t sign you in. Please try again or contact our support team."
      }
    >
      <Button
        id="login-error-try-again"
        onClick={() => logout({ returnTo: window.location.origin })}
      >
        Try again
      </Button>
      <Typography
        variant="h3"
        fontWeight={700}
        sx={{ textAlign: "center", mt: "24px" }}
      >
        If the issue persists, reach out to us at support@ourritual.com
      </Typography>
    </InfoLayout>
  );
};

export default ErrorLayout;
