import { Fragment } from "react";
import { useGetDrawersState } from "src/store/selectors";
import DefaultDrawer from "./components/DefaultDrawer";
import { componentsMapping } from "./utils/drawerComponentsMapping";
import { content as drawers } from "./content";

const Drawers = () => {
  const { activeDrawer } = useGetDrawersState();

  return (
    <>
      {drawers.map((drawer) => {
        const Component = componentsMapping[drawer.buttonId] || DefaultDrawer;
        if (activeDrawer !== drawer.query_param)
          return <Fragment key={drawer.buttonId} />;
        return <Component key={drawer.buttonId} drawer={drawer} />;
      })}
    </>
  );
};

export default Drawers;
