import { GWPlatforms } from "src/api/types";
import microphone from "src/assets/images/microphone.svg";
import document from "src/assets/images/document.svg";
import play from "src/assets/images/play.svg";
import audio from "src/assets/images/audio.svg";

export const content = {
  [GWPlatforms.VIDEOASK]: {
    label: "Journal Entry",
    type: "VIDEOASK",
    icon: microphone,
    id: 2,
  },
  [GWPlatforms.TYPEFORM]: {
    label: "Questionnaire",
    type: "TYPEFORM",
    icon: document,
    id: 1,
  },
  [GWPlatforms.WISTIA]: {
    label: "Video",
    type: "WISTIA",
    icon: play,
    id: 3,
  },
  [GWPlatforms.AUDIO]: {
    label: "Audio",
    type: "AUDIO",
    icon: audio,
    id: 4,
  },
};
