import React, { useEffect, useReducer } from "react";
import ScheduleSession from "./components/SessionView/ScheduleSession";
import { NextSession } from "./components/SessionView/NextSession";
import {
  useGetCompletedMatchingSessionQueryResolver,
  useGetCompletedWelcomeSessionQueryResolver,
  useGetExpertQueryResolver,
  useGetScheduledSessionQueryResolver,
  useGetSessionLinksQueryResolver,
  useGetUpcomingSessions,
} from "src/api/resolvers";

import Loader from "./components/Loader";
import usePrefetch from "src/hooks/usePrefetch";
import { useGetSessionEventsQuery } from "src/api/cms";
import { SessionManagerContext, initialState, reducer } from "./utils/reducer";
import ManagerSheet from "./components/ManagerSheet";
import CalendlyPopup from "./components/CalendlyPopup";
import SelectSessionTypeModal from "./components/SelectSessionTypeModal";
import useBookSessionAction from "./utils/useBookSessionAction";
import { useLocation } from "react-router-dom";
import { ROUTES } from "src/types/routes";
import CancelAlert from "./components/CancelAlert";
import { WelcomeBack } from "src/pages/Home/components/Notifications/PopUpNotifications/WelcomeBack";
import { ManagerWrapper } from "./styles";

const SessionManager = () => {
  const { data: scheduledSession, isLoading } =
    useGetScheduledSessionQueryResolver();

  const { isFetching: isPrefetching, isError } = usePrefetch(
    useGetSessionEventsQuery(),
    useGetUpcomingSessions(),
    useGetSessionLinksQueryResolver(),
    useGetCompletedWelcomeSessionQueryResolver(),
    useGetExpertQueryResolver(),
    useGetCompletedMatchingSessionQueryResolver()
  );

  const { pathname } = useLocation();

  const openSessionSchedule = useBookSessionAction();

  useEffect(() => {
    if (!isPrefetching && pathname === ROUTES.SCHEDULE_POPUP) {
      openSessionSchedule();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrefetching]);

  if (isLoading || isPrefetching || isError) {
    return <Loader />;
  }

  return (
    <>
      <ManagerWrapper>
        {scheduledSession?.next_session ? <NextSession /> : <ScheduleSession />}
      </ManagerWrapper>
      <ManagerSheet />
      <WelcomeBack />
      <CalendlyPopup />
      <SelectSessionTypeModal />
      <CancelAlert />
    </>
  );
};

const SessionManagerWithProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SessionManagerContext.Provider value={{ state, dispatch }}>
      <SessionManager />
    </SessionManagerContext.Provider>
  );
};

export default SessionManagerWithProvider;
