export const content = {};

export const placeholder = {
  caption: "",
  inputLabel: "",
  subtitle: "",
  headline: "",
  ids: {
    close: "",
    send: "",
    skip: "",
  },
};
