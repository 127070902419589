import React from "react";
import CarouselContextProvider from "./contexts/CarouselContext";
import { Content, ContentProps } from "./components/Content";

export const CarouselScreenLayout = (props: ContentProps) => {
  return (
    <CarouselContextProvider>
      <Content {...props} />
    </CarouselContextProvider>
  );
};
