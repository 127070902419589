import CmsBanners from "./CmsBanners";
import PopUpNotifications from "../Notifications/PopUpNotifications/index";

const Banners = () => {
  return (
    <>
      <PopUpNotifications />
      <CmsBanners />
    </>
  );
};

export default Banners;
