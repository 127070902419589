import React, { useState } from "react";
import PathwayCard from "../PathwayCard";
import { Module } from "src/api/types";
import { Subtitle, Title, Wrapper } from "./styles";
import { Button } from "src/components";
import { ButtonWrapper } from "src/pages/JourneyStarted/styles";
import { ROUTES } from "src/types/routes";
import { useNavigate } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useGetRecommendationCount } from "../../utils/useGetRecommendationCount";

const PathwaysList = ({ pathways }: { pathways: Module[] }) => {
  const { showDefaultRecommendedPathway } = useFlags();

  const [selectedModule, setSelectedModule] = useState<Module | null>({
    ...pathways[0],
    mainRecommendation: true,
  });

  const navigate = useNavigate();
  const redirectToTeaser = () => {
    navigate(ROUTES.PATHWAY_TEASER, { state: { pathway: selectedModule } });
  };

  const listProps = { module: selectedModule, onSelect: setSelectedModule };

  const recommendationCount = useGetRecommendationCount();
  return (
    <Wrapper>
      <List
        array={pathways.slice(0, recommendationCount)}
        title="Recommended for you"
        recommended
        subtitle={
          showDefaultRecommendedPathway ? "Start your journey here" : ""
        }
        {...listProps}
      />
      <List
        array={pathways.slice(recommendationCount)}
        title="Other Pathways"
        {...listProps}
      />
      <ButtonWrapper>
        <Button
          id={"select-pathway-button"}
          sx={{ width: "calc(100% - 64px) !important" }}
          onClick={redirectToTeaser}
        >
          Learn more
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

interface ListProps {
  array: Module[];
  title?: string;
  subtitle?: string;
  recommended?: boolean;
  module: Module | null;
  onSelect: (_: Module | null) => void;
}

const List = ({
  array,
  title,
  subtitle,
  recommended,
  module,
  onSelect,
}: ListProps) => (
  <>
    <Title variant="h4">{title}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
    {array?.map((item, i) => {
      const mainRecommendation = !i && !!recommended;
      return (
        <PathwayCard
          key={item.id}
          onClick={() => {
            onSelect({ mainRecommendation, ...item });
          }}
          {...item}
          recommended={mainRecommendation}
          selected={item.id === module?.id}
        />
      );
    })}
  </>
);

export default PathwaysList;
