import carousel1 from "src/assets/images/carousel1.png";
import carousel2 from "src/assets/images/carousel2.png";
import carousel3 from "src/assets/images/carousel3.png";
import carousel4 from "src/assets/images/carousel4.png";

export const content = [
  {
    title: "Get your relationship to another level ",
    description: "",
    button_text: "Let’s go",
    background_color: "#CBE1F3",
    step: 1,
    image: carousel1,
    id: 1,
  },
  {
    title: "Master practical skills and knowledge",
    description:
      "Easy-to-follow guidance in short videos, giving you the tools you need to do things differently.  ",
    button_text: "Next",
    background_color: "#D5F0D1",
    step: 2,
    image: carousel2,
    id: 2,
  },
  {
    title: "Uncover your relationship’s patterns",
    description:
      "Guided reflections & check-ins to improve your understanding of yourself and your partner.",
    button_text: "Next",
    background_color: "#DBD3F2",
    step: 3,
    image: carousel3,
    id: 3,
  },
  {
    title: "Get ongoing expert support ",
    description:
      "1:1 sessions with a dedicated expert to help you apply your new skills and overcome the challenges.",
    button_text: "Next",
    step: 4,
    background_color: "#CBE1F3",
    image: carousel4,
    id: 4,
  },
];
