import React from "react";
import { Carousel } from "src/components/Carousel";
import { useCarouselContext } from "../../contexts/CarouselContext";
import { CarouselData } from "../../types";
import { HeaderWrapper, Image } from "../styles";

export const Header = ({ data }: { data: CarouselData[] }) => {
  const { imagesCarouselRef } = useCarouselContext();

  return (
    <HeaderWrapper>
      <Carousel
        data={data}
        ref={imagesCarouselRef}
        renderItem={(item: any) => <Image src={item.image} />}
        dots={false}
      />
    </HeaderWrapper>
  );
};
