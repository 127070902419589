import React from "react";
import { useCarouselContext } from "../../contexts/CarouselContext";
import { Header } from "../Header";
import { MainCarousel } from "../MainCarousel";
import { Button } from "src/components";
import { Close, LeftArrow } from "src/assets/icons";
import { CloseButton, MainContentWrapper, ScreenLayout } from "../styles";
import { CarouselData } from "../../types";

export interface ContentProps {
  data: CarouselData[];
  onComplete: () => void;
  onClose?: () => void;
  children?: (step: number) => JSX.Element;
}

export const Content = ({
  data,
  onComplete,
  onClose,
  children,
}: ContentProps) => {
  const { step, next, prev } = useCarouselContext();

  if (!data.length) {
    return <></>;
  }

  const onButtonPress = () => {
    if (step < data.length - 1) {
      next();
    } else {
      onComplete();
    }
  };

  const onNavigationPress = () => {
    step ? prev() : onClose && onClose();
  };

  const button = data[step]?.button;

  return (
    <ScreenLayout>
      <CloseButton onClick={onNavigationPress}>
        {step ? <LeftArrow /> : onClose && <Close />}
      </CloseButton>
      <Header data={data} />
      <MainContentWrapper>
        <MainCarousel data={data} />
        <Button
          id={button.id || "carousel-next"}
          onClick={onButtonPress}
          disabled={!!button.disabled}
        >
          {button.text}
        </Button>
        {children && children(step)}
      </MainContentWrapper>
    </ScreenLayout>
  );
};
