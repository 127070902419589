import { Navigate, RouteObject } from "react-router-dom";
import TaskCompleted from "src/components/TaskCompleted";
import {
  HomePage,
  ModuleSelection,
  RitualInfo,
  JourneyStarted,
  Profile,
  VideoAsk,
  VideoTask,
  QuestionnaireTask,
  DesktopView,
  PreparingPathway,
  ExpertFeedback,
  CarouselPage,
  Journey,
  PathwayTeaser,
  AddPartnerForm,
  Chat,
  ChatInitial,
  CreateAccount,
  Authorization,
  LoginRedirect,
  JoinSession,
  JoinSessionUnauthorized,
  Subscription,
  JournalPrompt,
  JournalResponse,
} from "src/pages";
import RouteGuard, { RouteTypes } from "./RouteGuard";
import ContactUs from "src/pages/Contact Us";
import {
  AddPartnerStep,
  AllSetScreen,
  OnboardingInfo,
  BookSession,
} from "src/pages/Onboarding";
import { RouteObjectExtended, ROUTES } from "src/types/routes";
import { joinSessionUnauthorizedRedirect } from "./redirects";
import QRCodeLogin from "src/pages/QRCodeLogin";
import CheckEmail from "src/pages/CheckEmail";
import MemberDoesntExist from "src/components/ErrorScreens/MemberDoesntExist";
import ErrorLoginPaymentLayout from "src/components/ErrorScreens/ErrorLoginPaymentLayout";
import WelcomeOnboarding from "src/pages/WelcomeOnboarding";
import CompleteProfileSurvey from "src/pages/CompleteProfileSurvey";
import SubscriptionReactivateModal from "src/pages/Subscription/SubscriptionPaused";
import PlansModal from "src/pages/Pricing/PlansDescriptions";
import {
  CalculatingResults,
  PersonalizationDescription,
} from "src/entities/AppOnboardingQuestionnaire/pages";
import { OnboardingCarousel } from "src/entities/OnboardingCarousel";

const commonRoutes: RouteObjectExtended[] = [
  {
    path: ROUTES.QRCODE_LOGIN,
    element: <QRCodeLogin />,
  },
  {
    path: ROUTES.CHECK_EMAIL,
    element: <CheckEmail />,
  },
  {
    path: ROUTES.DESKTOP_VIEW,
    element: <DesktopView />,
  },
  {
    path: ROUTES.ONBOARDING_INITIAL,
    element: <OnboardingInfo />,
  },
  {
    path: ROUTES.ONBOARDING_CREATE_ACCOUNT,
    element: <CreateAccount />,
  },
  {
    path: ROUTES.ONBOARDING_WELCOME_LOGIN,
    element: <WelcomeOnboarding />,
  },
  {
    path: ROUTES.NO_ACCOUNT_FOUND,
    element: <MemberDoesntExist />,
  },
  {
    path: ROUTES.NO_ACTIVE_PLAN,
    element: <ErrorLoginPaymentLayout />,
  },
];

const publicRoutes: RouteObjectExtended[] = [
  {
    path: ROUTES.LOGIN,
    element: <Authorization />,
  },
  {
    path: ROUTES.SIGN_UP,
    element: <Authorization />,
  },
  {
    path: ROUTES.RITUAL_INFO,
    element: <RitualInfo />,
  },
  {
    path: ROUTES.JOIN_SESSION_LOGIN,
    element: <JoinSessionUnauthorized />,
  },
];

const privateRoutes: RouteObjectExtended[] = [
  {
    path: ROUTES.HOME,
    element: <HomePage />,
  },
  {
    path: ROUTES.MODULE_SELECTION,
    element: <ModuleSelection />,
  },
  {
    path: ROUTES.JOURNEY_STARTED,
    element: <JourneyStarted />,
  },
  {
    path: ROUTES.PROFILE,
    element: <Profile />,
  },
  {
    path: ROUTES.VIDEOASK,
    element: <VideoAsk />,
  },
  {
    path: ROUTES.VIDEO_TASK,
    element: <VideoTask />,
  },
  {
    path: ROUTES.AUDIO_TASK,
    element: <VideoTask />,
  },
  {
    path: ROUTES.QUESTIONNAIRE_TASK,
    element: <QuestionnaireTask />,
  },
  {
    path: ROUTES.ALL_TASKS_COMPLETED,
    element: <TaskCompleted type="all" />,
  },
  {
    path: ROUTES.PREPARING_PATHWAY,
    element: <PreparingPathway />,
  },
  {
    path: ROUTES.TASK_COMPLETED,
    element: <TaskCompleted />,
  },
  {
    path: ROUTES.EXPERT_FEEDBACK,
    element: <ExpertFeedback />,
  },
  {
    path: ROUTES.CONTACT_US,
    element: <ContactUs />,
  },
  {
    path: ROUTES.CAROUSEL,
    element: <OnboardingCarousel />,
  },
  {
    path: ROUTES.JOURNEY,
    element: <Journey />,
  },
  {
    path: ROUTES.PATHWAY_TEASER,
    element: <PathwayTeaser />,
  },
  {
    path: ROUTES.ADD_PARTNER_FORM,
    element: <AddPartnerForm />,
  },
  {
    path: ROUTES.CHAT,
    element: <Chat />,
  },
  {
    path: ROUTES.CHAT_INITIAL,
    element: <ChatInitial />,
  },
  {
    path: ROUTES.ONBOARDING_BOOK_MATCHING_SESSION,
    element: <BookSession />,
  },
  {
    path: ROUTES.ONBOARDING_BOOK_WELCOME_SESSION,
    element: <BookSession />,
  },
  {
    path: ROUTES.ONBOARDING_ADD_PARTNER,
    element: <AddPartnerStep />,
  },
  {
    path: ROUTES.ONBOARDING_ALL_SET,
    element: <AllSetScreen />,
  },
  {
    path: ROUTES.SUBSCRIPTION,
    element: <Subscription />,
  },
  {
    path: ROUTES.COMPLETE_PROFILE,
    element: <CompleteProfileSurvey />,
  },
  {
    path: ROUTES.REACTIVATE_SUBSCRIPTION,
    element: <SubscriptionReactivateModal />,
  },
  {
    path: ROUTES.PLANS,
    element: <PlansModal />,
  },
  {
    path: ROUTES.JOURNAL_PROMPT,
    element: <JournalPrompt />,
  },
  {
    path: ROUTES.JOURNAL_RESPONSE,
    element: <JournalResponse />,
  },

  // Deep links
  {
    path: ROUTES.LOGIN_REDIRECT,
    element: <LoginRedirect />,
  },
  {
    path: ROUTES.SCHEDULE_POPUP,
    element: <HomePage />,
  },
  {
    path: ROUTES.JOIN_SESSION,
    element: <JoinSession />,
    redirect: joinSessionUnauthorizedRedirect,
  },

  {
    path: ROUTES.PERSONALIZATION_DESCRIPTION,
    element: <PersonalizationDescription />,
  },
  {
    path: ROUTES.CALCULATING_RESULTS,
    element: <CalculatingResults />,
  },
];

export const routes: RouteObject[] = [
  ...publicRoutes.map(RouteGuard(RouteTypes.PUBLIC)),
  ...privateRoutes.map(RouteGuard(RouteTypes.PRIVATE)),
  ...commonRoutes,
  {
    path: ROUTES.NOT_FOUND,
    element: <Navigate to={ROUTES.HOME} />,
  },
];

export { ROUTES } from "src/types/routes";
