import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetMemberQuery, useUpdateModuleMutation } from "src/api/main";
import { Module } from "src/api/types";
import { WistiaEmbed, Button } from "src/components";
import useLocationState from "src/hooks/useLocationState";
import { ROUTES } from "src/types/routes";
import Header from "./components/Header";
import { ButtonContainer, Content, Description, ReturnButton } from "./styles";

const PathwayTeaser = () => {
  const navigate = useNavigate();
  const { data: member } = useGetMemberQuery();
  const pathway = useLocationState<Module>("pathway");
  const [updateModule] = useUpdateModuleMutation();

  const handleClick = () => {
    if (!member || !pathway) return;

    updateModule({
      memberId: member.uuid,
      moduleId: pathway.module_id,
      body: { selected: true },
    })
      .unwrap()
      .then(() => navigate(ROUTES.HOME))
      .catch(() => {});
  };

  const goBack = () => {
    navigate(ROUTES.MODULE_SELECTION);
  };

  if (!pathway) return <></>;

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Header />
      <Content>
        <div>
          <Typography variant="h2">Goal</Typography>
          <Description variant="h3">{pathway.goal}</Description>
          {pathway?.teaser && (
            <WistiaEmbed
              link={pathway.teaser}
              isAudio={false}
              fullScreen={false}
            />
          )}
        </div>
        <ButtonContainer>
          <Button id="join-pathway-button" onClick={handleClick}>
            Join Pathway
          </Button>
          <ReturnButton id="back-browsing-button" variant="h3" onClick={goBack}>
            Back to browsing
          </ReturnButton>
        </ButtonContainer>
      </Content>
    </div>
  );
};

export default PathwayTeaser;
