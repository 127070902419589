import { Button, InfoLayout } from "src/components";

const RitualInfo = () => {
  const handleClick = () => {
    window.location.href = process.env.REACT_APP_MATCHING_DOMAIN || "";
  };

  return (
    <InfoLayout
      title="What’s in OurRitual?"
      subtitle={`We help turning relationship challenges into triumphs, using realistic tools and skills. Progress you can feel.\n \nFor starters, we’d like to\nlearn a bit about you `}
    >
      <Button id="redirect-to-funnel-button" onClick={handleClick}>
        Let’s Go
      </Button>
    </InfoLayout>
  );
};

export default RitualInfo;
