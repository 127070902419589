import React from "react";
import { AlertTypes, useSessionManagerContext } from "../../utils/reducer";
import useActions from "../../utils/useActions";
import { StyledModal } from "../SelectSessionTypeModal/styles";
import { Wrapper, AlertTitle, AlertButton, AlertText } from "./styles";
import useIsMatchingPlanCategory from "src/hooks/useIsMatchingPlan";
import { content as rescheduleRestriction } from "../../content";

const CancelAlert = () => {
  const {
    state: { alertType, alertLink },
  } = useSessionManagerContext();
  const { hideAlert, setCalendlyLink } = useActions();
  const isMatchingCategory = useIsMatchingPlanCategory();

  const alertProps = {
    [AlertTypes.CANCEL]: {
      title: "cancel",
      subtitle: "Canceling",
      button: "cancellation",
    },
    [AlertTypes.RESCHEDULE]: {
      title: "reschedule",
      subtitle: "Rescheduling",
      button: "rescheduling",
    },
  };

  const props = alertType && alertProps[alertType];

  return (
    <StyledModal open={!!alertType} onClose={hideAlert}>
      <Wrapper>
        <AlertTitle>
          Are you sure you want to {props?.title} your session?
        </AlertTitle>
        <AlertText>
          {isMatchingCategory ? (
            <>
              Normally, changes made within 36 hours of scheduling result in the
              loss of session credits. But since we're just getting to know each
              other, feel free to {props?.title} without any worries.
            </>
          ) : (
            <>
              {props?.subtitle} a session under{" "}
              {rescheduleRestriction.hours_before_start} hours in advance
              results in a lost session for that week.
            </>
          )}
        </AlertText>
        <AlertButton id="keep_session" onClick={hideAlert}>
          Keep my session
        </AlertButton>
        <AlertButton
          id={`continue-${props?.button}`}
          onClick={() => setCalendlyLink(alertLink || "")}
          color="outlined-secondary"
          sx={{ color: "grey.600" }}
        >
          Continue {props?.button}
        </AlertButton>
      </Wrapper>
    </StyledModal>
  );
};

export default CancelAlert;
