import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { cmsUrls } from "./urls";
import {
  Module,
  CMSResponseType,
  Part,
  ModuleRelation,
  Growthwork,
  YouareOnYourWay,
  ModulePartGrowthworks,
  ExpertCms,
  ExpertCmsResponse,
  SessionEvent,
  SubscriptionDetails,
  SubscriptionDetailsCms,
} from "./types";
import {
  getModulePartGrowthworks,
  sortModuleRelation,
  transformImage,
} from "./utils";
import { getPartGrowthworks } from "./utils/responseTransformers/partGrowthworksResponseTransformer";

const transformCmsResponse = (res: CMSResponseType<any, true>) => {
  return res.data.map(({ attributes, id }) => ({ ...attributes, id }));
};

const cmsUrl = process.env.REACT_APP_CMS_URL || "";

export const cmsApi = createApi({
  reducerPath: "cms",
  baseQuery: fetchBaseQuery({
    baseUrl: cmsUrl,
  }),
  endpoints: (builder) => ({
    getModules: builder.query<Module[], void>({
      query: cmsUrls.modules,
      transformResponse: (res: CMSResponseType<Module, true>) => {
        return transformCmsResponse(res).map((item) => ({
          ...item,
          module_id: String(item.id),
          icon: transformImage(item.icon),
          teaser: item.teaser?.data?.attributes.link || "",
        }));
      },
    }),

    getModuleRelation: builder.query<ModuleRelation[], string | undefined>({
      query: cmsUrls.moduleRelation,
      transformResponse: (res: CMSResponseType<ModuleRelation, true>) => {
        const total = res.meta.pagination.total;
        return transformCmsResponse(res).map((item) => ({
          ...item,
          total,
          module: {
            data: {
              id: item.module.data.id,
              attributes: {
                ...item.module.data.attributes,
                module_id: String(item.module.data.id),
              },
            },
          },
        }));
      },
    }),

    getCurrentPart: builder.query<
      Part,
      {
        partOrder: string;
        moduleId: string;
      }
    >({
      query: cmsUrls.currentPart,
      transformResponse: (res: CMSResponseType<ModuleRelation, true>) => {
        const part = transformCmsResponse(res)[0]?.part;
        if (!part) return null;
        return { ...part.data.attributes, part_id: part.data.id };
      },
    }),

    getPartGrowthworks: builder.query<
      Growthwork[],
      { partId: string; expertId: string; moduleId: string }
    >({
      query: cmsUrls.getPartGrowthworks,
      transformResponse: (
        res: CMSResponseType<ModuleRelation, true>,
        _,
        { expertId }
      ) => {
        const growthworks = transformCmsResponse(res);
        return getPartGrowthworks(growthworks, expertId)
          .sort((a, b) => a.gw_order - b.gw_order)
          .map((item) => {
            const gwAttributes = item?.gw.data.attributes;
            const image: any = gwAttributes.image;

            return {
              ...gwAttributes,
              gw_order: item.gw_order,
              relation_cms_id: item?.id,
              image: image ? transformImage(image) : "",
            };
          });
      },
    }),

    getParts: builder.query<Part[], void>({
      query: cmsUrls.parts,
      transformResponse: (res: CMSResponseType<Part, true>) => {
        return transformCmsResponse(res).map((item) => ({
          ...item,
          module: String(item.module?.data?.id),
          part_id: item.id,
        }));
      },
    }),

    getYoureOnYourWay: builder.query<YouareOnYourWay, number>({
      query: cmsUrls.youreOnYourWay,
      transformResponse: (res: CMSResponseType<YouareOnYourWay, true>) => {
        return transformCmsResponse(res)[0];
      },
    }),

    getVideoGrowthWorks: builder.query<ModulePartGrowthworks, string>({
      query: cmsUrls.videoGrowthworks,
      transformResponse: (res: CMSResponseType<ModuleRelation, true>) => {
        const response = transformCmsResponse(res).sort(sortModuleRelation);
        return getModulePartGrowthworks(response);
      },
    }),

    getExpertCms: builder.query<ExpertCms | undefined, string>({
      query: cmsUrls.expert,
      transformResponse: (res: CMSResponseType<ExpertCmsResponse, true>) => {
        const expert = res.data[0]?.attributes;
        if (!expert) return;
        return {
          ...expert,
          avatar: expert.avatar?.data && transformImage(expert.avatar),
        };
      },
    }),

    getSessionEvents: builder.query<SessionEvent[], void>({
      query: cmsUrls.sessionEvents,
      transformResponse: (res: CMSResponseType<SessionEvent, true>) =>
        transformCmsResponse(res).map((item) => ({
          ...item,
          image: transformImage(item.image),
        })),
    }),

    getSubscriptionDetails: builder.query<SubscriptionDetails, number>({
      query: cmsUrls.subscriptionDetails,
      transformResponse: (
        res: CMSResponseType<SubscriptionDetailsCms, true>
      ) => {
        const details = res.data[0].attributes;
        return {
          ...details,
          items_included: details.items_included.split("\n"),
        };
      },
    }),

    getSubscriptionProfileDetails: builder.query<SubscriptionDetails, string>({
      query: cmsUrls.subscriptionProfileDetails,
      transformResponse: (
        res: CMSResponseType<SubscriptionDetailsCms, false>
      ) => {
        const details = res.data.attributes;
        return {
          ...details,
          items_included: details.items_included.split("\n"),
        };
      },
    }),

    getDefaultRecommendedPathway: builder.query<Module, void>({
      query: cmsUrls.defaultRecommendedPathway,
      transformResponse: (
        res: CMSResponseType<{ pathway: CMSResponseType<any, false> }, false>
      ) => {
        const pathwayData = res.data.attributes?.pathway;
        const pathway = pathwayData?.data;
        return { ...pathway.attributes, id: pathway.id };
      },
    }),
  }),
});

export const {
  useGetModulesQuery,
  useGetPartsQuery,
  useGetModuleRelationQuery,
  useGetPartGrowthworksQuery,
  useGetYoureOnYourWayQuery,
  useGetCurrentPartQuery,
  useGetVideoGrowthWorksQuery,
  useGetExpertCmsQuery,
  useGetSessionEventsQuery,
  useGetSubscriptionDetailsQuery,
  useGetSubscriptionProfileDetailsQuery,
  useGetDefaultRecommendedPathwayQuery,
} = cmsApi;
