import React, {
  PropsWithChildren,
  Ref,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";

interface ContextValue {
  contentCarouselRef: any;
  imagesCarouselRef: any;
  next: () => void;
  prev: () => void;
  step: number;
  setStep: (step: number) => void;
}

const CarouselContext = createContext<ContextValue>({} as ContextValue);

export const useCarouselContext = () => useContext(CarouselContext);

const CarouselContextProvider = ({ children }: PropsWithChildren) => {
  const contentCarouselRef = useRef<any>(null);
  const imagesCarouselRef = useRef<any>(null);

  const [step, setStep] = useState(0);

  const next = () => {
    imagesCarouselRef.current?.slickNext();
    contentCarouselRef.current?.slickNext();
    setStep((state) => state + 1);
  };

  const prev = () => {
    imagesCarouselRef.current?.slickPrev();
    contentCarouselRef.current?.slickPrev();
    setStep((state) => state - 1);
  };

  const value = {
    contentCarouselRef,
    imagesCarouselRef,
    next,
    prev,
    step,
    setStep,
  };

  return (
    <CarouselContext.Provider value={value}>
      {children}
    </CarouselContext.Provider>
  );
};

export default CarouselContextProvider;
