import RitualAnalytics from "client-analytics";
import { useEffect } from "react";

const useCioInAppEvents = () => {
  const onMessageOpened = (name: string) => (event: any) => {
    RitualAnalytics.track(`member_appmessage_${name}`, {
      messageId: event.detail.messageId,
    });

    if (window.analytics) {
      window.analytics.track(`member_appmessage_${name}`, {
        messageId: event.detail.messageId,
      });
    }
  };

  useEffect(() => {
    const setListener = () => {
      if (window._cio) {
        window._cio.on("in-app:message-dismissed", onMessageOpened("closed"));

        window._cio.on("in-app:message-opened", onMessageOpened("displayed"));

        window._cio.on("in-app:message-action", onMessageOpened("clicked"));
      } else {
        setTimeout(setListener, 100);
      }
    };

    setListener();
  }, []);
};

export default useCioInAppEvents;
