import React from "react";
import LayoutWithoutHeader from "src/components/LayoutWithoutHeader";
import FAQFooter from "./components/FAQFooter";
import { Subtitle, Title } from "./styles";
import Loading from "src/components/Loading";
import Chats from "./components/Chats";
import { useStream } from "../Chat/contexts/StreamContext";

const ChatInitial = () => {
  const { channelsLoading } = useStream();

  if (channelsLoading) {
    return <Loading />;
  }

  return (
    <LayoutWithoutHeader Footer={FAQFooter}>
      <Title>Chat</Title>
      <Subtitle>Talk to us</Subtitle>
      <Chats />
    </LayoutWithoutHeader>
  );
};

export default ChatInitial;
