import { styled } from "@mui/material";
import theme from "src/theme";

export const Dot = styled("div")({
  width: "12px",
  height: "8px",
  background: theme.palette.grey[200],
  borderRadius: "50px",
});

export const DotsWrapper = styled("ul")({
  marginTop: "20px",

  li: {
    width: "fit-content !important",
  },

  ".slick-active": {
    div: {
      width: "32px",
      height: "8px",
      background: theme.palette.grey[600],
      borderRadius: "50px",
    },
  },
});
