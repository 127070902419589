import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Content, Dot, DotsWrapper } from "../../styles";
import { Typography } from "@mui/material";
import { forwardRef } from "react";
import { content as data } from "../../content";

const settings = {
  dots: true,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  customPaging: () => <Dot />,
  appendDots: (dots: any) => <DotsWrapper>{dots}</DotsWrapper>,
};

const CarouselContent = forwardRef(({ afterChange }: any, ref) => {
  return (
    <Slider ref={ref as any} {...settings} afterChange={afterChange}>
      {data?.slice(1).map(
        (
          item // skip first item from cms, according to design
        ) => (
          <Content key={item.step}>
            <Typography variant="h1">{item.title}</Typography>
            <Typography variant="body2">{item.description}</Typography>
          </Content>
        )
      )}
    </Slider>
  );
});

export default CarouselContent;
