import drawer1 from "src/assets/images/drawer1.png";
import drawer2 from "src/assets/images/drawer2.png";

export const content = [
  {
    title: "Moving forward",
    text: "You are ready for the next steps on your pathway, keep it up!",
    button: "Continue exploring",
    buttonId: "complete_part_drawer",
    query_param: "complete_part=true",
    secondary_button: "",
    secondary_button_id: "",
    icon: drawer1,
    id: 2,
  },
  {
    title: "Great work, way to go!",
    text: "Your expert got your Growthwork, and will review it before your next session together.",
    button: "Got it",
    buttonId: "waiting_for_review_drawer",
    query_param: "waiting_for_review=true",
    secondary_button: "",
    secondary_button_id: "",
    icon: drawer2,
    id: 1,
  },
];
