import React from "react";
import Carousel from "src/pages/Carousel";
import { ROUTES } from "src/router";
import { useGetOnboardingCarouselSlidesQuery } from "./api/cms";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useNavigate } from "react-router-dom";
import Loading from "src/components/Loading";
import { CarouselScreenLayout } from "src/components/CarouselScreenLayout";

export const OnboardingCarousel = () => {
  const { updatedOnboardingCarousel } = useFlags();
  const navigate = useNavigate();

  const { data, isLoading } = useGetOnboardingCarouselSlidesQuery();

  if (!updatedOnboardingCarousel) {
    return <Carousel />;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CarouselScreenLayout
      data={data || []}
      onComplete={() => navigate(ROUTES.JOURNEY_STARTED)}
    />
  );
};
