import { GWPlatforms } from "src/api/types";
import successIcon from "src/assets/images/success_icon.png";
import partCompletedIcon from "src/assets/images/part_completed_icon.png";

export const content = {
  all: {
    title: "A round of applause!",
    subtitle:
      "Getting closer to the relationship you want, and it’s all thanks to you.\n\nYour expert will be going over everything you sent in to make sure you’re making the most of this Pathway.",
    button: "I’m proud of myself",
    buttonId: "part-completed-button",
    icon: partCompletedIcon,
  },
  [GWPlatforms.VIDEOASK]: {
    title: "We see you",
    subtitle:
      "Reflection can bring a lot to the surface, and it’s not always easy, yet so worth it. While you ponder, we're processing your response – it may take a few moments!",
    button: "Another step forward",
    buttonId: "videoask-completed-button",
    icon: successIcon,
  },
  [GWPlatforms.WISTIA]: {
    title: "All done!",
    subtitle:
      "Take a moment to let everything sink in. You’re on the right path!",
    button: "Onwards!",
    buttonId: "wistia-completed-button",
    icon: successIcon,
  },
  [GWPlatforms.AUDIO]: {
    title: "All done!",
    subtitle:
      "Take a moment to let everything sink in. You’re on the right path!",
    button: "Onwards!",
    buttonId: "wistia-completed-button",
    icon: successIcon,
  },
  [GWPlatforms.TYPEFORM]: {
    title: "Growthwork complete",
    subtitle:
      "This helps you and your expert get the full picture of where you’re at on your path.",
    button: "I’m proud of myself",
    buttonId: "typeform-completed-button",
    icon: successIcon,
  },
};
