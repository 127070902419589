import React from "react";
import { Footer } from "src/components";
import illustration from "src/assets/images/footer_illustration.png";

const FAQFooter = () => {
  return (
    <Footer
      title="Find answers in our FAQ"
      subtitle="Get help anytime and get the answers your need"
      onClick={() =>
        window.open(`${process.env.REACT_APP_MAIN_SITE}/faq`, "_blank")
      }
      button="Go to FAQ"
      src={illustration}
    />
  );
};

export default FAQFooter;
