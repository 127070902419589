import { cmsApi } from "src/api/cms";
import { urls } from "./urls";
import { transformCmsResponse, transformImage } from "src/api/utils";
import { CarouselData } from "src/components/CarouselScreenLayout/types";

const onboardingCarouselCmsApi = cmsApi.injectEndpoints({
  endpoints: (builder) => ({
    getOnboardingCarouselSlides: builder.query<CarouselData[], void>({
      query: urls.getOnboardingSlides,
      transformResponse: (res: any) =>
        transformCmsResponse(res).map((item) => ({
          ...item,
          image: transformImage(item.image),
        })),
    }),
  }),
});

export const { useGetOnboardingCarouselSlidesQuery } = onboardingCarouselCmsApi;
