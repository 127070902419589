import { useGetMemberQuery } from "src/api/main";
import { LayoutWithBackButton } from "src/components";
import { PathwaysList } from "./components";
import { Title, Subtitle } from "./styles";
import Loading from "src/components/Loading";
import useGetPathways from "./utils/useGetPathways";

const ModuleSelection = () => {
  const { data: member, isFetching: isMemberFetching } = useGetMemberQuery();

  const { pathways, isLoading } = useGetPathways();

  if (isLoading || isMemberFetching) return <Loading />;

  return (
    <LayoutWithBackButton>
      <Title>Welcome {member?.first_name},</Title>
      <Subtitle>Select the Pathway you’d like to start with.</Subtitle>
      <PathwaysList pathways={pathways || []} />
    </LayoutWithBackButton>
  );
};

export default ModuleSelection;
