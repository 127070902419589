import React from "react";
import { FeedbackPopup } from "ritual-ui";
import { useGetMemberQuery, useSendInAppFeedbackMutation } from "src/api/main";
import { closeFeedback } from "src/store/feedbackReducer";
import { useGetFeedbackState } from "src/store/selectors";
import { useAppDispatch } from "src/store/hooks";
import { content, placeholder } from "./utils/content";

export const InAppFeedback = () => {
  const dispatch = useAppDispatch();

  const { type, opened, event_fields } = useGetFeedbackState();

  const [sendFeedback] = useSendInAppFeedbackMutation();
  const { data: member } = useGetMemberQuery();

  const onSubmit = async (body: { rating: number; free_text: string }) => {
    if (type) {
      await sendFeedback({
        member: member?.uuid || "",
        type,
        ...body,
        event_fields,
      });
    }
  };

  const closePopup = () => {
    dispatch(closeFeedback());
  };

  const onSkip = () => {
    closePopup();
    if (type) {
      sendFeedback({ member: member?.uuid || "", type, skipped: true });
    }
  };

  return (
    <FeedbackPopup
      onSubmit={onSubmit}
      onSkip={onSkip}
      isOpen={opened}
      onClose={closePopup}
      {...(type ? (content as any)[type] : placeholder)}
    />
  );
};
