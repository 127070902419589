import React, { forwardRef, Ref } from "react";
import Slider from "react-slick";
import { Dot, DotsWrapper } from "./styles";

const settings = {
  dots: true,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipe: false,
  arrows: false,
  customPaging: () => <Dot />,
  appendDots: (dots: any) => <DotsWrapper>{dots}</DotsWrapper>,
};

export const Carousel = forwardRef(
  ({ data, renderItem, ...rest }: any, ref: Ref<any>) => {
    return (
      <Slider ref={ref as any} {...settings} {...rest}>
        {data?.map(renderItem)}
      </Slider>
    );
  }
);
